<template>
  <GlobalDialog class="flex flex-col gap-4 w-[450px] dialog">
    <h2 class="text-black font-bold" v-text="localize(`title.${anyError(null) ? 'error' : 'default'}`)"/>
    <div class="flex flex-col justify-center">
      <template v-if="stage === 0">
        <div class="w-full relative h-[300px] rounded-xl flex flex-col justify-center" style="background-color: #f2f7ff; user-select: none; border: 2px solid var(--avv-primary--color); color: var(--avv-primary-hover--color);">
          <input type="file" class="absolute inset-0 opacity-0 cursor-pointer" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="uploadFile">
          <p class="pointer-events-none flex flex-col items-center">
            <i class="material-icons text-6xl" aria-hidden="true">cloud_upload</i>
            <span v-text="localize('dropzone')"/>
          </p>
        </div>
      </template>
      <template v-else-if="stage === 1">
        <div class="w-full h-[300px] flex justify-center items-center">
          <div class="flex loader"></div>
        </div>
      </template>
      <template v-else-if="stage === 2">
        <div v-if="anyError(null)" class="w-full h-[calc(300px-1.75rem)] flex flex-col gap-1 overflow-y-auto">
          <template v-if="anyError('file_generic') || anyError('file_missing')">
            <b v-text="localize('errors.file')"/>
            <template v-if="anyError('file_generic')">
              <ul style="list-style: initial;" class="ml-8">
                <li v-for="text in data.errors.file_generic" :key="text" v-text="text"/>
              </ul>
            </template>
            <template v-if="anyError('file_missing')">
              <span v-text="localize('errors.file_missing')"/>
              <ul style="list-style: initial;" class="ml-8">
                <li v-for="text in data.errors.file_missing" :key="text" v-text="text"/>
              </ul>
            </template>
          </template>
          <template v-if="anyError('data_generic') || anyError('data_missing') || anyError('data_invalid')">
            <b v-text="localize('errors.data')"/>
            <template v-if="anyError('data_generic')">
              <ul style="list-style: initial;" class="ml-8">
                <li v-for="text in data.errors.data_generic" :key="text" v-text="text"/>
              </ul>
            </template>
            <template v-if="anyError('data_missing')">
              <span v-text="localize('errors.data_missing')"/>
              <ul style="list-style: initial;" class="ml-8">
                <li v-for="text in data.errors.data_missing" :key="text" v-text="text"/>
              </ul>
            </template>
            <template v-if="anyError('data_invalid')">
              <span v-text="localize('errors.data_invalid')"/>
              <ul style="list-style: initial;" class="ml-8">
                <li v-for="text in data.errors.data_invalid" :key="text" v-text="text"/>
              </ul>
            </template>
          </template>
        </div>
        <div v-else class="w-full h-[300px] flex flex-col gap-2 justify-center items-center">
          <b v-text="localize('queue.title')"/>
          <p><span v-text="localize('queue.link')"/> <a :href="data.link" style="color: var(--avv-primary--color);" target="_blank" v-text="localize('queue.here')"/></p>
          <p v-if="data.size > 50" class="mx-4 text-sm text-center mt-6" v-text="localize('queue.large')"/>
        </div>
        <div v-if="anyError(null)" class="text-sm mt-2" v-text="localize('errors.notice')"/>
      </template>
    </div>
    <div class="flex justify-between">
      <button class="avv-button secondary" @click="close" v-text="localize('close')"/>
      <button v-if="stage == 2 && anyError(null)" class="avv-button primary" @click="retry" v-text="localize('retry')"/>
      <a v-if="stage == 2 && !anyError(null)" class="avv-button primary" href="/documents" v-text="localize('continue')"/>
    </div>
  </GlobalDialog>
</template>
<script lang="ts">
  import { defineComponent, ref } from "vue";

  import GlobalDialog from '../dialogs/GlobalDialog.vue'
  import Utils from '../utils'

  export default defineComponent({
    name: 'ImportDialog',
    components: { GlobalDialog },
    props: [ 'templates' ],
    emits: [ 'callback' ],
    setup(props, ctx) {
      const data = ref<null | { link: string, size: number, errors: { data_invalid: string[], data_missing: string[], file_generic: string[], file_missing: string[] } }>(null)
      const stage = ref<0 | 1 | 2 | 3>(0)

      const close = () => {
        ctx.emit('callback')
      }

      const retry = () => {
        data.value = null
        stage.value = 0
      }

      const anyError = (type: null | string) => {
        if (data.value && data.value.errors) {
          const errors = data.value.errors
          if (type === null) {
            return Object.values(errors).some((list) => list.length > 0)
          } else {
            return errors[type] && errors[type].length > 0
          }
        } else {
          // No errors present
          return false
        }
      }

      const uploadFile = (e: Event) => {
        data.value = null

        const input = e.target as HTMLInputElement
        const file = input.files![0] as File

        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return console.warn('File type mismatch')
        if (file.size > 256e6) return console.warn('File too large')

        const formData = new FormData()
        formData.append('file', file)
        formData.append('template_ids', props.templates.join(','))

        stage.value = 1

        Utils.axios.post('/documents/imports/xlsx', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          data.value = response.data
          stage.value = 2
        })
      }

      const localize = (key: string) => window.localizeText(`documents.excel.import.${key}`)

      return {
        uploadFile,
        close,
        retry,
        stage,
        data,
        anyError,
        localize
      }
    }
  })
</script>
