import { Controller } from "stimulus"
import axios from 'axios'
import type { AxiosResponse } from 'axios'

const spinner = `<tr class="infinite-scroll-spinner"><td colspan="10"><div class="infinite-scroll-spinner loader no-size h-[30px] w-[30px] no-m mx-auto my-3 flex justify-center items-center no-w avv-table-text whitespace-nowrap py-3"></div></td></tr>`

export default class extends Controller {
  static targets = ["table", "entries", "pagination"]

  private loading: boolean | undefined
  private loadingToFindLastPosition: boolean | undefined
  declare readonly tableTarget: HTMLElement
  declare readonly entriesTarget: HTMLElement
  declare readonly paginationTarget: HTMLElement

  scrollHandler = () => {
    if (this.entriesTarget.classList.contains("infinite-scroll-off")) return

    if (!this.loading && (this.tableTarget.scrollHeight <= this.tableTarget.clientHeight || this.bottomHit())) this.prepareLoad()
  }

  async handleScrollingToLastPosition(scrollToId: string) {
    let scrollToElement = document.getElementById(scrollToId)
    if (scrollToElement) this.scrollElementToView(scrollToElement, 500)
    else {
      while (!scrollToElement) {
        this.loadingToFindLastPosition = true
        await this.prepareLoad()
        scrollToElement = document.getElementById(scrollToId)
      }
      this.scrollElementToView(scrollToElement)
      this.loadingToFindLastPosition = false
    }
  }

  scrollElementToView = (scrollToElement: HTMLElement, timeout = 0) => {
    const targetPosition = scrollToElement.getBoundingClientRect().top
    const topOffset = 400
    setTimeout(() => this.tableTarget.scrollTo({top: targetPosition - topOffset, behavior: 'smooth'}), timeout)
  }

  connect() {
    const observer = new MutationObserver(() => {
      this.tableTarget.addEventListener("scroll", this.scrollHandler)
    })
    const tableContainer = this.tableTarget.closest('.table-container')
    if (tableContainer) observer.observe(tableContainer, { childList: true, subtree: true })

    this.tableTarget.addEventListener("scroll", this.scrollHandler)
    document.addEventListener("DOMContentLoaded", this.scrollHandler)

    const scrollToId = new URLSearchParams(window.location.search).get("scroll_to_id");
    if (this.tableTarget.classList.contains('main-index-table') && scrollToId) this.handleScrollingToLastPosition(scrollToId)
  }

   bottomHit() {
    const pixelTolerance = 50
    return this.tableTarget.scrollTop + this.tableTarget.clientHeight >= this.tableTarget.scrollHeight - pixelTolerance
   }

  showSpinner() {
    this.entriesTarget.insertAdjacentHTML("beforeend", spinner)
  }

  hideSpinner() {
    this.entriesTarget.querySelector(".infinite-scroll-spinner")?.remove()
  }

  async prepareLoad(): Promise<void> {
    this.loading = true
    let url = this.paginationTarget.querySelector("a[rel=next]")?.getAttribute("href") as string
    if (!url) {
      this.loading = false
      return
    }

    this.showSpinner()
    await this.loadMore(url + "&scrolling=true")
    this.loading = false
  }

  async loadMore(url: string){
    const response: AxiosResponse<{
      content_html: string;
      pagination_html: string;
      drive_files: [];
    }> = await axios.get(url, { headers: { accept: 'application/json' } })

    const data = response.data
    this.hideSpinner()

    if (!data.drive_files.length) {
      this.entriesTarget.classList.add('infinite-scroll-off')
      this.loading = false
      return
    }

    if (this.paginationTarget.classList.contains('mutation-version')) {
      const newUrl = new URL(url, window.location.href)
      const folderId = parseInt(newUrl.searchParams.get("folder_id") as string)
      const currentPage = parseInt(newUrl.searchParams.get("page") as string)

      sidebarStore.commit('SET_FOLDER_DATA', {folderId, currentPage, folderFiles: data.drive_files})
    }
    else {
      this.entriesTarget.insertAdjacentHTML("beforeend", data.content_html)
      this.paginationTarget.innerHTML = data.pagination_html
    }
    this.loading = false

    if (!this.loadingToFindLastPosition) setTimeout(() => this.scrollHandler(), 500)
  }
}