import ExportApi from '@api/TemplateVersions/ExportApi'
import type {ExposeStimulus} from "./base_controller";
import BulkActionsController from './bulk_actions_controller'

export interface TemplateBulkActionsController extends ExposeStimulus<typeof TemplateBulkActionsController> {}
export class TemplateBulkActionsController extends BulkActionsController {
  static targets = ['folderIdInput'] as const

  addToFolder (event) {
    const folderId = event.currentTarget.dataset.folderId
    this.folderIdInputTarget.value = folderId
    this.submitForm('add_to_folder')
  }

  removeFromFolder () {
    this.submitForm('remove_from_folder')
  }

  gsubText () {
    this.submitForm('gsub_text')
  }

  exportAvvoka () {
    const selectedInputs = Array.from<HTMLInputElement>(document.querySelectorAll('input[type="checkbox"][name="template_ids[]"]:checked'))
    const templateVersionIds = selectedInputs.map((input) => input.dataset.templateVersionId)

    window.avv_download(ExportApi.create.path(), { template_version_ids: templateVersionIds, formats: 'document_avvoka' })
  }

  importAvvoka (event: PointerEvent) {
    event.preventDefault()

    avv_dialog({
      uploadTitle: `<strong>${localizeText('template.docx_upload.title')}</strong>`,
      uploadDescription: localizeText('template.docx_upload.desc'),
      uploadSuccessCallback: (instance, _, data) => {
        instance.closeDialog();

        avv_dialog({
          snackStyle: data.type,
          snackMessage: data.text
        })

        setTimeout(() => window.location.reload(), 3000)
      },
      uploadErrorCallback: (instance, _, data) => {
        instance.closeDialog();
        
        avv_dialog({
          snackStyle: data.type,
          snackMessage: data.text
        })
      },
      formDataModifier: (formData) => {
        const target = event.target! as HTMLElement
        const element = target.closest('[data-profile]') as HTMLAnchorElement

        formData.append('profile_id', element.dataset.profile)
        if (element.dataset.folderId) {
          formData.append('folder_id', element.dataset.folderId)
        }
      },
      dropzoneOptions: {
        url: "/templates/imports/avvoka",
        paramName: "file",
        uploadMultiple: true,
        acceptedFiles: '.zip,.avvoka,.docx',
        timeout: 90 * 1000,
        parallelUploads: 100
      }
    })
  }
}

export default TemplateBulkActionsController
