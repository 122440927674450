<template>
  <div class="round corners cursor-default badge">
    <div
      v-if="config.type !== 'headless'"
      class="flex items-center text-sm pointer-events-none bg-gray-200 px-2"
    >
      <span v-text="config.value" />
    </div>
    <template v-for="(segment, index) of filterSegments">
      <template v-if="isInteractiveSegment(segment)">
        <component
          :is="getSegmentComponent(segment.type)"
          :key="`${segment.type}-${index}`"
          :segment="segment"
          :value="segment.value"
          :edit="true"
          @change="(val: string) => filterSegmentsChange(val, segment.index)"
        />
      </template>
      <template v-else>
        <component
          :is="getSegmentComponent(segment.type)"
          :key="`${segment.type}-${index}`"
          :value="segment.default"
        />
      </template>
    </template>
    <button
      v-if="isRemovable"
      class="px-[0.4rem] cursor-pointer bg-gray-200 hover:bg-gray-300"
      @click="filterRemove"
    >
      <i class="material-icons text-gray-600">clear</i>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import {
  type FilterConfigSegment,
  type FilterConfigEntry,
  type FilterEntry,
  isInteractiveSegment,
  getSegmentComponent
} from './../shared'

const props = defineProps<{
  filter: FilterEntry | undefined
  config: FilterConfigEntry
}>()

const emit = defineEmits<{
  (e: 'remove'): void
  (e: 'change', key: string, values: Array<string | null>): void
}>()

const config = computed(() => props.config)
const filter = computed(() => props.filter)

const isRemovable = computed(
  () => config.value.type !== 'fixed' && config.value.type !== 'headless'
)

const filterKey = computed(() => config.value.key)

const filterSegments = computed(() => {
  const mapper = (segment: FilterConfigSegment) => {
    if (isInteractiveSegment(segment)) {
      segment.value = filter.value?.values?.[segment.index] ?? null
    }

    return segment
  }

  return config.value.segments.map(mapper)
})

const filterRemove = () => {
  emit('remove')
}

const canRemoveOnChange = (val: string, index: number) => {
  const segment = filterSegments.value.find(
    (segment) => isInteractiveSegment(segment) && segment.index === index
  )

  return (
    segment &&
    segment.default === val &&
    (segment.type !== 'toggle' ||
      config.value.type === 'fixed' ||
      config.value.type === 'headless') &&
    segment.type !== 'text'
  )
}

const filterSegmentsChange = (val: string, index: number) => {
  if (canRemoveOnChange(val, index)) {
    filterRemove()
  } else {
    const values = filterSegments.value
      .filter(isInteractiveSegment)
      .map((segment) => segment.value)

    values[index] = val

    emit('change', filterKey.value, values)
  }
}
</script>
