import Utils from "../features/utils"
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

import ConfigDialog from '../features/docu_sign_dashboard/ConfigDialog.vue'
import PublishDialog from '../features/docu_sign_dashboard/PublishDialog.vue'

export interface DocuSignController extends ExposeStimulus<typeof DocuSignController> {}
export class DocuSignController extends StimulusControllerBase {
  static targets = ['template', 'list'] as const
  static values = {
    config: String,
    batch: String,
    dashboardLink: String
  }

  updateFields (event: PointerEvent) {
    event.preventDefault()

    const target = (event.target as HTMLElement).closest('a') as HTMLAnchorElement
    const batch = target.dataset.batch!

    void this.openModal(batch)
  }

  async openModal (batch: string) {
    const { data } = await Utils.axios.get(`/docu_sign/batches/${batch}/modal`)
    document.body.insertAdjacentHTML('beforeend', data)
  }

  addToModal () {
    this.listTarget.appendChild(this.templateTarget.content.cloneNode(true))
  }

  removeFromModal (event: PointerEvent) {
    const target = event.target! as HTMLElement
    const node = target.closest('[data-item]')!
    node.remove()
  }

  closeModal () {
    document.getElementById('docu-sign-modal')?.remove()
  }

  dashboardLink () {
    navigator.clipboard.writeText(this.dashboardLinkValue)

    window.avv_toast({
      message: 'Link was copied to clipboard!'
    })
  }

  dashboardPublish () {
    window.avv_dialog({
      vueDialog: PublishDialog,
      callback: (reload: boolean) => {
        if (reload) window.location.reload()
      },
      props: {
        batch_id: this.batchValue
      }
    })
  }

  dashboardConfig () {
    window.avv_dialog({
      vueDialog: ConfigDialog,
      callback: (reload: boolean) => {
        if (reload) window.location.reload()
      },
      props: JSON.parse(this.configValue)
    })
  }

  dashboardSearch (event: KeyboardEvent) {
    const target = event.target as HTMLInputElement
    const value = target.value.toLowerCase()

    const container = document.querySelector<HTMLElement>('.docu-sign-dashboard-table')
    for (const row of container.querySelectorAll<HTMLTableRowElement>('tbody tr')) {
      if (!value || Array.from(row.querySelectorAll<HTMLTableCellElement>('td')).some((cell) => cell.innerText.toLowerCase().includes(value))) {
        row.style.display = ''
      } else {
        row.style.display = 'none'
      }
    }
  }
}

export default DocuSignController
