<template>
  <button
    ref="wrapperElement"
    class="relative px-2 badge-segment bg-gray-200 min-w-[26px]"
    :tabindex="isEditable ? '0' : '-1'"
    :class="{
      'pointer-events-none': !isEditable || isOpen,
      'bg-gray-300': isOpen,
      'bg-gray-200 hover:bg-gray-300 cursor-pointer': isEditable && !isOpen,
      'min-w-[120px]': isOpen
    }"
    @focusout="hideOptions"
    @click="showOptions"
  >
    <span class="text-sm" v-text="displayValue" />
    <div v-show="isOpen" ref="popupElement" class="fixed z-[1001]">
      <div
        class="pointer-events-auto bg-white list flex flex-col whitespace-nowrap max-h-[450px]"
        :class="{ scrolling: optionsScrollingSearch }"
      >
        <div
          v-if="optionsScrollingSearch"
          class="text-sm border-b border-gray-300 flex justify-between"
        >
          <input
            ref="searchElement"
            v-model="searchValue"
            tabindex="0"
            spellcheck="false"
            placeholder="Search ..."
            class="px-2 py-1 outline-none"
          />
          <button
            :style="{ visibility: searchValue ? 'visible' : 'hidden' }"
            type="button"
            class="avv-danger-color cursor-pointer flex items-center px-1"
            @click="clearSearchValue"
          >
            <i class="material-icons transform rotate-45">add</i>
          </button>
        </div>
        <button
          v-for="option of searchOptions"
          :key="option.key"
          :disabled="option.disabled"
          :title="option.title"
          type="button"
          tabindex="0"
          class="hover:bg-gray-200 focus:bg-gray-200 cursor-pointer py-1 px-2 text-sm text-left"
          @click="(event) => setValue(event, option.key)"
          v-text="option.value"
        />
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted, nextTick } from 'vue'
import { type FilterSegmentSelect } from './../../shared'

const props = defineProps<{
  value: string | null
  segment: FilterSegmentSelect
  setup?: boolean
  edit?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'open'): void
  (e: 'close'): void
}>()

const open = ref(false)
const wrapperElement = ref<HTMLDivElement>()
const popupElement = ref<HTMLElement>()
const parentElement = computed(() =>
  wrapperElement.value?.closest('.badge-container')
)

const value = computed(() => props.value)
const options = computed(() => props.segment.options)
const optionsScrollingSearch = computed(
  () => props.segment.search || options.value.length > 15
)

const isEditable = computed(
  () => props.edit && props.segment.options.length > 1
)
const isOpen = computed(() => props.setup || (props.edit && open.value))
const isEmpty = computed(
  () => typeof value.value === 'undefined' || value.value === null
)

const searchValue = ref('')
const searchElement = ref<HTMLInputElement>()

const clearSearchValue = () => {
  searchValue.value = ''
}

const searchOptions = computed(() => {
  const term = searchValue.value.trim().toLowerCase()
  if (term) {
    return options.value.filter((option) =>
      option.value.toLowerCase().includes(term)
    )
  } else {
    return options.value
  }
})

watch(
  () => props.setup,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      open.value = false
    }
  }
)

const displayValue = computed(() => {
  if (isEmpty.value) {
    return props.segment.placeholder
  } else {
    const mapper = ({ key }: FilterSegmentSelect['options'][number]) =>
      key === value.value

    return options.value.find(mapper)?.value
  }
})

const setValue = (event: Event, val: string) => {
  event.preventDefault()
  event.stopPropagation()

  open.value = false

  emit('change', val)
}

const showOptions = () => {
  if (!isEditable.value) return

  open.value = true
}

const hideOptions = (e: FocusEvent) => {
  if (!isEditable.value) return
  const isFocusOnChildren = !!wrapperElement.value?.contains(
    e.relatedTarget as HTMLElement
  )
  open.value = isFocusOnChildren
}

const updatePopupPosition = () => {
  if (isOpen.value && wrapperElement.value) {
    const wrapper = wrapperElement.value as HTMLElement
    const wrapperRect = wrapper.getBoundingClientRect()

    const parent = parentElement.value as HTMLElement
    const parentRect = parent.getBoundingClientRect()

    const element = popupElement.value as HTMLElement
    element.style.left = `${Math.min(
      parentRect.right - element.clientWidth,
      Math.max(parentRect.left, wrapperRect.left)
    )}px`
    element.style.top = `${wrapperRect.top + 32}px`

    window.requestAnimationFrame(() => updatePopupPosition())
  }
}

const onSegmentStateChange = (open: boolean) => {
  if (!open) return

  if (options.value.length === 1) {
    // Set first and only value immediately
    emit('change', options.value[0].key)
  } else if (props.setup && props.segment.default) {
    // If default is set in setup mode
    emit('change', props.segment.default)
  }

  nextTick(() => searchElement.value?.focus())

  const wrapper = wrapperElement.value as HTMLElement
  wrapper.scrollIntoView({ inline: 'center', block: 'nearest' })

  window.requestAnimationFrame(() => updatePopupPosition())
}

watch(isOpen, (value: boolean) => onSegmentStateChange(value))
onMounted(() => onSegmentStateChange(props.setup))

// Emit open event if segment was opened
watch(open, (value: boolean) => {
  if (value) {
    emit('open')
  } else {
    emit('close')
  }
})
</script>
