import { Controller } from 'stimulus'

const INPUT_VALUE_GETTERS: { [key: string]: (el: HTMLInputElement) => string } =
  {
    text: (el: HTMLInputElement) => el.value,
    checkbox: (el: HTMLInputElement) => (el.checked ? 'true' : 'false')
  }

export default class VisibilityController extends Controller {
  controllerCache: { [key: string]: string } = {}
  conditionsCache: Array<{
    element: HTMLElement
    conditions: { [key: string]: string }
  }> = []

  connect() {
    const conditionedElements: NodeListOf<HTMLInputElement> =
      this.element.querySelectorAll('[data-visibility-condition]')
    for (const element of conditionedElements) {
      const conditions: { [key: string]: string } = {}
      const visibilityCondition = element.dataset.visibilityCondition
      if (!visibilityCondition) continue

      for (const condition of visibilityCondition.split(',')) {
        const [name, value] = condition.split('=', 2)

        conditions[name] = value
      }

      this.conditionsCache.push({
        element,
        conditions
      })
    }

    const controllingElements: NodeListOf<HTMLInputElement> =
      this.element.querySelectorAll('[data-visibility-controller]')
    for (const element of controllingElements) {
      const name = element.dataset.visibilityController
      if (!name) continue

      const gets = INPUT_VALUE_GETTERS[element.type] ?? INPUT_VALUE_GETTERS.text

      element.addEventListener('input', () => {
        this._stash(name, gets(element))
        this._update()
      })

      this._stash(name, gets(element))
    }

    this._update()
  }

  _stash(controllerName: string, controllerValue: string) {
    this.controllerCache[controllerName] = controllerValue
  }

  _update() {
    for (const { element, conditions } of this.conditionsCache) {
      const visible = Object.entries(conditions).every(
        ([key, value]) => this.controllerCache[key] === value
      )
      element.style.display = visible ? '' : 'none'
    }
  }
}
