import ImportDialog from './../features/excel_templates/ImportDialog.vue'
import ExportDialog from './../features/excel_templates/ExportDialog.vue'
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface ExcelTemplatesController extends ExposeStimulus<typeof ExcelTemplatesController> {}
export class ExcelTemplatesController extends StimulusControllerBase {
  static values = {
    template: String
  }

  import () {
    window.avv_dialog({
      vueDialog: ImportDialog,
      callback: () => {

      },
      props: {
        templates: this._selectedTemplates()
      }
    })
  }

  export () {
    window.avv_dialog({
      vueDialog: ExportDialog,
      callback: () => {},
      props: {
        templates: this._selectedTemplates()
      }
    })
  }

  _selectedTemplates () {
    if (this.templateValue) {
      return [this.templateValue]
    } else {
      const form = document.getElementById('templates-form')!
      const checkboxes = form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]:not([data-check-all]):not([data-skip])')
      return Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value)
    }
  }
}

export default ExcelTemplatesController