import {LoadingHelpers} from "../features/loading";
import {IsHTMLElement, onDomContentLoaded} from "../features/dom_utils";
import {resolveQuestionnaireConflicts} from "../features/questionnaire_merge/utils";
import Utils from "../features/utils";
import {CreateDocumentFormBuilder} from "../features/document_creation";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";
const axios = Utils.axios

export interface TemplatePacksController extends ExposeStimulus<typeof TemplatePacksController> {}
export class TemplatePacksController extends StimulusControllerBase {

  static values = {templatePackId: String}

  connect() {
    document.addEventListener('apply-condition-builder', (e) => {
      const {astString, wrapper} = e.detail
      const humanAst = window.Ast.astStringToHumanReadable(astString)
      const display = wrapper.closest('tr').previousElementSibling.querySelector("[id*='generation-condition']")
      display.innerText = humanAst
      avv_dialog({snackMessage: 'Condition successfully applied', snackStyle: 'notice'})
    })

    onDomContentLoaded(() => {
      const conditionDisplays = document.querySelectorAll<HTMLElement>("[id*='generation-condition']")
      conditionDisplays.forEach(display => {
        const ast = window.Ast.astStringToHumanReadable(display.innerText) ?? ''
        if(display.innerText) display.innerText = ast
      })
    })
  }

  async startPack(event: MouseEvent){
    const callback = (value: boolean) => {
      if(value){
        if(!event.target) return
        if(!IsHTMLElement(event.target)) return
        if(event.target.getAttribute('disabled') == 'true') return
        LoadingHelpers.startLoading()
        const element = event.target.closest('[data-template-ids]')
        if(!IsHTMLElement(element)) return
        const template_ids = JSON.parse(element.dataset.templateIds as string)
        const ordered_template_ids = JSON.parse(element.dataset.orderedTemplateIds as string)
        const template_pack_id = element.dataset.templatePackId
        CreateDocumentFormBuilder.createAndSubmitForm({template_ids, ordered_template_ids, template_pack_id})
      }
    }
    
    if(!IsHTMLElement(event.target)) return
    const element = event.target.closest('[data-template-ids]')
    if(!IsHTMLElement(element)) return
    const template_pack_id = element.dataset.templatePackId
    const response = await axios.get<{conflicts: Record<string, unknown>}>(`/template_packs/${template_pack_id}/conflicts`)

    const hasConflicts = Object.keys(response.data.conflicts).length > 0
    if(hasConflicts){
      const confirmMessage = 'There are conflicts that must be resolved at the base template level that may create an impaired experience when generating your pack. If you would like to resolve these, please click Cancel, and navigate to the ‘Template level’ section in the diagnostics tab for more information. If you’d still like to Launch your pack, please click OK'
      avv_dialog({confirmMessage, confirmTitle: 'Unresolved conflicts', confirmCallback: callback})
    } else callback(true)
  }

  save(){
    this.fillQuestionnaireInput()
    this.savePackInfo()
  }

  fillQuestionnaireInput(){
    if(!window.qStore) return
    const questionnaireInput = document.getElementById('template_pack_questionnaire') as HTMLInputElement
    questionnaireInput.value = this.questionnaire
  }

  get questionnaire(){
    window.qStore.state.questions.forEach(q => {
      if(q.cond == 'null') q.cond = undefined
    })
    const avvFormat = resolveQuestionnaireConflicts(window.qStore.state.questions, this.resolutions, window.templatePackQuestionnaireData.new_questionnaires)
    return avvFormat.substring(avvFormat.indexOf(".questionnaire"), avvFormat.lastIndexOf(".end questionnaire") + ".end questionnaire".length)
  }

  get resolutions(){
    const resolutionsInput = document.getElementById('template_pack_resolutions') as HTMLInputElement
    return JSON.parse(resolutionsInput.value || '{}')
  }

  savePackInfo(){
    document.forms[0].submit()
  }
}

export default TemplatePacksController
