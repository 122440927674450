<template>
  <div class="h-full">
   <AStepper :steps="conflictingAttributes" :stepIndex="currentAttIndex" :buttonless="true" @update:stepIndex="updateCurrentAttIndex">
     <ABorderedBox :class="'min-h-8/10'" :label="decode(label)" :desc="desc">
       <template v-slot:header>
         <div class="w-full grid place-items-center">
          <AButtonGroup :buttons="sections" @click="onButtonGroupClick"></AButtonGroup>
         </div>
         <p class="font-medium text-gray-500 py-4" v-text="helpText"></p>
       </template>
       <template v-if="currentAttribute">
         <p class="mb-4 font-medium" v-text="conflictHelperText"></p>
         <div class="flex w-full gap-x-2">
           <ul role="list" class="space-y-3 w-1/2">
             <li
                 v-for="([value, id], index) in possibleValues"
                 :key="currentAttribute+currentConflictingField+value"
                 :class="{'bg-primary-500 avv-primary-bg-text': breakingSwitch ? false : isResolvedValue(value, currentAttribute, currentConflictingField, index), 'hover:bg-primary-500 hover:avv-primary-bg-text cursor-pointer': !breakingSwitch}"
                 class="overflow-hidden rounded-md bg-white px-6 py-4 shadow w-full overflow-ellipsis flex justify-between"
                 @click="breakingSwitch ? null : resolve(value, currentAttribute, currentConflictingField, true)"
             >
               <span v-text="parseValue(value, currentConflictingField) || 'No value set'"></span>
               <div>
                <i class="material-icons" :title="id">description</i>
                <i v-if="isFullyResolved(value, currentAttribute, currentConflictingField)" class="material-icons">done</i>
               </div>
             </li>
           </ul>
         </div>
         <div class="flex gap-x-2 mt-4">
          <AButton v-if="currentAttIndex !== 0" :color="'secondary'" :shade="50" :text-color="'black'" :label="'Previous item'" @click="currentAttIndex--"></AButton>
          <AButton v-if="currentAttIndex !== conflictingAttributes.length - 1" :label="'Next item'" @click="currentAttIndex++"></AButton>
         </div>
       </template>
       <div v-else class="h-full w-full grid place-items-center text-4xl" style="margin-top: 25vh">You have no conflicts</div>
     </ABorderedBox>
   </AStepper>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, reactive, watch } from 'vue'
  import AStepper from "../_abstract/AStepper.vue"
  import ABorderedBox from "../_abstract/ABorderedBox.vue"
  import AButton from "../_abstract/AButton.vue"
  import AButtonGroup from "../_abstract/AButtonGroup.vue"
  import Utils from "../utils";
  import { fieldToHumanReadable, fieldHelpText } from "./utils";

  const props = defineProps<{
    conflicts: Record<string, any>
    breakingConflicts: Record<string, any>
    saveButton?: boolean
    resolvedValues: Record<string, any>
  }>()

  const sections = [
    {key: 'nonbreaking', text: 'Pack Level'},
    {key: 'breaking', text: 'Template Level'},
  ]

  const onButtonGroupClick = (key: string) => {
    breakingSwitch.value = key == 'breaking'
  }

  const conflictHelperText = computed(() => {
    return fieldHelpText(currentConflictingField.value, breakingSwitch.value)
  })

  const label = computed(() => currentAttribute.value ? `Attribute: ${currentAttribute.value}` : '')
  const desc = computed(() => currentConflictingField.value ? `Conflict Type: ${fieldToHumanReadable(currentConflictingField.value)}` : '')
  const helpText = computed(() => {
    if(breakingSwitch.value) return 'The conflicts below can only be resolved at template level. Please resolve them by amending the base templates from the \'Templates\' tab. To find out which template each attribute belongs to, please hover over the black document icon below'
    else return 'Please resolve the conflict by selecting which permutation of the attribute should be prioritised'
  })
  const breakingSwitch = ref(false)
  watch(breakingSwitch, () => {
    currentAttIndex.value = 0
    currentConflictingFieldIndex.value = 0
  })

  const updateCurrentAttIndex = (index: number) => {
    currentAttIndex.value = index
    currentConflictingFieldIndex.value = 0
  }
  const incrementCurrentAttIndex = () => {
    currentAttIndex.value++
    currentConflictingFieldIndex.value = 0
  }
  const currentAttIndex = ref(0)
  const conflictsObject = computed(() => breakingSwitch.value ? props.breakingConflicts : props.conflicts)
  const conflictingAttributes = computed(() => Object.keys(conflictsObject.value))
  const currentAttribute = computed(() => conflictingAttributes.value[currentAttIndex.value])
  const isLastAttribute = computed(() => conflictingAttributes.value.length - 1 === currentAttIndex.value)

  const currentConflictingFieldIndex = ref(0)
  const conflictingFields = computed(() => Object.keys(conflictsObject.value[currentAttribute.value] ?? {}))
  const currentConflictingField = computed(() => conflictingFields.value[currentConflictingFieldIndex.value])
  const isLastConflictingField = computed(() => conflictingFields.value.length - 1 === currentConflictingFieldIndex.value)
  const incrementCurrentConflictingFieldIndex = () => currentConflictingFieldIndex.value++

  const possibleValues = computed(() => {
    return conflictsObject.value[currentAttribute.value][currentConflictingField.value]
  })
  const withoutValues = (conflicts: Record<string, any>) => {
    const resolvedValues = Utils.deepCopy(conflicts)
    Object.keys(resolvedValues).forEach(att => {
      resolvedValues[att] = {}
    })
    return resolvedValues
  }
  const resolvedValues = reactive({...withoutValues(props.conflicts), ...props.resolvedValues})
  const isResolvedValue = (value: string, attribute: string, conflictingField: string, index: number) => {
    if(Object.keys(resolvedValues[attribute]).length < 1) return index == 0
    return window.AvvParser.decode(resolvedValues[attribute][conflictingField]) == window.AvvParser.decode(value)
  }

  // saved resolution
  const isFullyResolved = (value: string, attribute: string, conflictingField: string) => {
    if(!props.resolvedValues[attribute]) return false
    return window.AvvParser.decode(props.resolvedValues[attribute][conflictingField]) == window.AvvParser.decode(value)
  }
  const attAndFieldIsResolved = (attribute: string, conflictingField: string) => {
    return !!resolvedValues[attribute][conflictingField]
  }
  const resolve = (value: string, attribute: string, conflictingField: string, preventIncrements = false) => {
    resolvedValues[attribute][conflictingField] = window.AvvParser.encode(value)
    if(preventIncrements) return
    if(isLastConflictingField.value && !isLastAttribute.value) incrementCurrentAttIndex()
    else if(!isLastConflictingField.value) incrementCurrentConflictingFieldIndex()
  }

  type Strategy = 0 | -1

  const autoResolve = (strategy: Strategy) => {
    Object.keys(conflictsObject.value).forEach(att => {
      Object.keys(conflictsObject.value[att]).forEach(field => {
        const chosenValue = (conflictsObject.value[att][field] as any[]).at(strategy)
        resolve(chosenValue, att, field, true)
      })
    })
  }

  const decode = window.AvvParser.decode

  const parseValue = (value: string, type: string) => {
    let decodedValue = window.AvvParser.decode(value)
    switch (type) {
      case 'required':
        decodedValue = decodedValue ? 'Required' : 'Not Required'
        break
      case 'condition':
        decodedValue = Ast.astStringToHumanReadable(value)
        break
    }
    return decodedValue
  }

  watch(resolvedValues, newValue => {
    const resolutionsInput = document.getElementById('template_pack_resolutions') as HTMLInputElement
    resolutionsInput.value = JSON.stringify(newValue)
  })

</script>
