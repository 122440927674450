import { updateDialog } from "../features/datasheets/document_update_dialog"
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface DatasheetRowBindsController extends ExposeStimulus<typeof DatasheetRowBindsController> {}
export class DatasheetRowBindsController extends StimulusControllerBase {
  submit(event: Event) {
    const form = this.element as HTMLFormElement

    if (form.dataset.binds) {
      event.stopPropagation()
      event.preventDefault()

      const { bindings, values } = JSON.parse(form.dataset.binds)

      updateDialog(
        { bindings, values },
        (documentIds: null | Array<string>) => {
          if (Array.isArray(documentIds)) {
            for (const documentId of documentIds) {
              const input = document.createElement('input')

              input.type = 'hidden'
              input.name = 'update_bound_document_ids[]'
              input.value = documentId

              form.appendChild(input)
            }

            form.submit()
          }
        }
      )
    }
  }
}

export default DatasheetRowBindsController