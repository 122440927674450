import axios from "axios"
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface DatasheetsFetchController extends ExposeStimulus<typeof DatasheetsFetchController> {}
export class DatasheetsFetchController extends StimulusControllerBase {
    static targets = ['template', 'container'] as const

    datasheetData?: { [key: string]: { id: number, name: string, headers: { [key: string]: { id: number, name: string } } } }

    connect () {
        this.reload(null)
    }

    add (event) {
        event.preventDefault()

        this.containerTarget.insertAdjacentElement('beforeend', this.templateTarget.content.cloneNode(true).querySelector('div'))
        this.reload(null)
    }

    delete (event) {
        event.preventDefault()
        event.target.closest('.dependent-ds-item').remove()
    }

    async reload (event) {
        if (event?.target?.id === 'profiles-select' || !this.datasheetData) {
            await this.fetch()
        }

        // Reload datasheet_id
        const datasheetSelect = document.getElementById('ds-datasheet-id') as HTMLSelectElement
        if (!datasheetSelect) return

        this.replaceOptions(datasheetSelect, this.datasheetData)

        // Reload datasheet_display_header_name
        const displayHeaderNameSelect = document.getElementById('ds-datasheet-display-header-name') as HTMLSelectElement
        const displayHeaderNameOptions = this.datasheetData[datasheetSelect.value]?.headers
        this.replaceOptions(displayHeaderNameSelect, displayHeaderNameOptions)

        // Reload datasheet_dependencies
        const depenciesHeaderSelects = document.querySelectorAll<HTMLSelectElement>('.ds-datasheet-dependencies-header')
        for (const select of Array.from(depenciesHeaderSelects)) {
            if (displayHeaderNameOptions) {
                const selectOptions = Object.assign({}, displayHeaderNameOptions)
                delete selectOptions[displayHeaderNameSelect.value]

                this.replaceOptions(select, selectOptions)
            } else {
                this.replaceOptions(select)
            }
        }
    }

    replaceOptions (select: HTMLSelectElement, options?: { [key: string]: { id: number, name: string } }) {
        const selectedValue = select.value

        for (const option of Array.from(select.options)) {
            if (option.value) option.remove()
        }

        if (options) {
            // Insert options only if present, otherwise leave just 'default option'
            for (const [value, object] of Object.entries(options)) {
                select.add(new Option(object.name, value))
            }

            select.value = options[selectedValue] ? selectedValue : ''
        } else {
            select.value = ''
        }
    }

    async fetch () {
        const profileSelect = document.getElementById('profiles-select') as HTMLSelectElement
        const profileValue = profileSelect?.value

        const queryParams = new URLSearchParams()
        queryParams.set('profile_id', profileValue);
        const url = `/custom_attributes/fetch_datasheets/?${queryParams.toString()}`

        const { data } = await axios.get(url)

        this.datasheetData = data
    }
}

export default DatasheetsFetchController