import axios from 'axios'

let numClicks = 0
let singleClickTimer: NodeJS.Timeout

import { Controller } from 'stimulus'

export default class extends Controller {
  private isClickAndHold: boolean | undefined
  private startX: number | null = null
  private startY: number | null = null
  canClose() {
    const requiredInputs = document.querySelectorAll('.required-value-missing-on-backend')

    if (requiredInputs.length > 0) {
      avv_dialog({
        snackStyle: 'error',
        snackMessage: localizeText('properties_sidebar.save_required_metadata_fields')
      })
      return false
    }
    return true
  }

  fileViewerModalTrigger(event: Event) {
    const eventTarget = event?.target as HTMLElement
    const isFileViewerModalTrigger = eventTarget?.classList?.contains(
      'file-viewer-modal-trigger'
    )
    if (isFileViewerModalTrigger)
      return document.querySelector(
        `tr[data-file-id="${eventTarget?.dataset?.fileId as string}"]`
      )
    return null
  }

  resolvePropertiesToggle(event: Event) {
    const eventTarget = event?.target as HTMLElement
    if (this.isClickAndHold || !this.canClose()) return

    const eventTrigger = (this.fileViewerModalTrigger(event) ||
      eventTarget?.closest('.event-trigger')) as HTMLElement
    if (!eventTrigger) return

    const fileId =
      (eventTrigger.dataset.fileId as string) ||
      (eventTrigger.dataset.folderId as string)
    const { fileType, docId, docParticipant } = eventTrigger.dataset as {
      fileType: string;
      docId: string;
      docParticipant: string;
    }
    numClicks++

    // distinguishes between a single click and a double click:
    if (numClicks === 1) {
      singleClickTimer = setTimeout(() => {
        numClicks = 0
        if (
          eventTarget.classList.contains('no-properties-link') ||
          eventTarget.closest('.no-properties-link')
        )
          return

        this.toggleBgColor(eventTrigger)

        const link = document.querySelector(
          `a[href="/${fileType?.split('-')[0]}/${fileId}/edit"]`
        ) as HTMLElement
        link?.click()
      }, 200)
    } else if (numClicks === 2) {
      clearTimeout(singleClickTimer)
      numClicks = 0

      if (eventTrigger.classList.contains('file-viewer-type')) {
        this.openFileViewer(event, fileId)
        return
      }

      this.clickLink(fileType, docParticipant, docId, fileId)
    }
  }

  clickLink(
    fileType: string,
    docParticipant: string,
    docId: string,
    fileId: string
  ) {
    let link

    switch (fileType) {
      case 'folders':
        link = `/drive?folder_id=${fileId}`
        break
      case 'drive-file':
        link = `/drive/${fileId}`
        break
      case 'drive-doc':
        link = docParticipant ? `/documents/${docId}/continue` : null
        break
    }
    if (link) window.location.href = link
    if (!link) {
      const docLink = document.querySelector(
        `a[data-doc-id="${docId}"]`
      ) as HTMLElement
      docLink?.click()
    }
  }

  openFileViewer = async (event: Event, driveFileId: string | null = null): Promise<void> => {
    return new Promise(async (resolve) => {
      const fileId = driveFileId || (event?.target as HTMLElement)?.dataset?.fileId;
      if (!fileId) {
        resolve()
        return
      }

    this.closePropsSidebar()
      await new Promise((resolve) => setTimeout(resolve, 250))

      const url = `/drive/${fileId}/open_file_viewer`
      const response = await axios.get(url)
      const success = response.status.toString().startsWith('2')
      if (success) {
        const fileViewerModal = document.querySelector('#file-viewer-modal')
        if (fileViewerModal) fileViewerModal.innerHTML = response.data
      }

      resolve();
    });
  }

  toggleBgColor(trigger: HTMLElement | null = null) {
    const elWithBlueBg = document.querySelector('.blue-tint-bg')

    if (elWithBlueBg) elWithBlueBg.classList.remove('blue-tint-bg')
    if (elWithBlueBg !== trigger && trigger) trigger.classList.add('blue-tint-bg');
  }

  handleMouseDown = (event: MouseEvent) => {
    this.isClickAndHold = false
    this.startX = event.clientX
    this.startY = event.clientY

    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp, { once: true })
  }

  handleMouseMove = (event: MouseEvent) => {
    if (this.startX !== null && this.startY !== null) {

      const deltaX = Math.abs(event.clientX - this.startX)
      const deltaY = Math.abs(event.clientY - this.startY)

      const draggingTolerance = 5

      if (deltaX > draggingTolerance || deltaY > draggingTolerance) {
        // If the mouse has moved beyond the draggingTolerance, consider it as dragging
        this.isClickAndHold = true
        this.startX = null
        this.startY = null

        document.removeEventListener('mousemove', this.handleMouseMove)
      }
    }
  }

  handleMouseUp = (event: MouseEvent) => {
    const eventTrigger = (event.target as HTMLElement).closest('.event-trigger')
    if (!this.isClickAndHold) {
      if (eventTrigger?.classList.contains('file-viewer-type')) this.openFileViewer(event)
      document.removeEventListener('mousemove', this.handleMouseMove)
    }
    this.isClickAndHold = true
  }

  closePropsSidebar = () => {
    const propsSidebarCloseBtnEl = <HTMLInputElement>(
      document.querySelector('.props-sidebar-close-icon')
    )
    propsSidebarCloseBtnEl?.click()
  }
}
