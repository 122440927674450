<template>
  <button
    class="relative px-2 badge-segment bg-gray-200 min-w-[26px]"
    :tabindex="isEditable ? '0' : '-1'"
    :class="{
      'pointer-events-none': !isEditable || isOpen,
      'bg-gray-300': isOpen,
      'bg-gray-200 hover:bg-gray-300 cursor-pointer': isEditable && !isOpen,
      'min-w-[120px]': isOpen
    }"
    @click="toggleValue"
  >
    <span class="text-sm" v-text="displayValue" />
  </button>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue'
import { type FilterSegmentToggle } from './../../shared'

const props = defineProps<{
  value: string | null
  segment: FilterSegmentToggle
  setup?: boolean
  edit?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'open'): void
  (e: 'close'): void
}>()

const open = ref(false)

const value = computed(() => props.value)
const options = computed(() => props.segment.options)

const isEditable = computed(
  () => props.edit && props.segment.options.length > 1
)
const isOpen = computed(() => props.setup || (props.edit && open.value))
const isEmpty = computed(
  () => typeof value.value === 'undefined' || value.value === null
)

watch(
  () => props.setup,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      open.value = false
    }
  }
)

const displayValue = computed(() => {
  if (isEmpty.value) {
    return props.segment.placeholder
  } else {
    const mapper = ({ key }: FilterSegmentToggle['options'][number]) =>
      key === value.value

    return options.value.find(mapper)?.value
  }
})

const toggleValue = () => {
  if (!isEditable.value) return

  if (isEmpty.value || value.value === options.value[0].key) {
    emit('change', options.value[1].key)
  } else {
    emit('change', options.value[0].key)
  }
}

const onSegmentStateChange = (open: boolean) => {
  if (!open) return

  if (options.value.length === 1) {
    // Set first and only value immediately
    emit('change', options.value[0].key)
  } else if (props.setup && props.segment.default) {
    // If default is set in setup mode
    emit('change', props.segment.default)
  }
}

watch(isOpen, (value: boolean) => onSegmentStateChange(value))
onMounted(() => onSegmentStateChange(props.setup))

// Emit open event if segment was opened
watch(open, (value: boolean) => {
  if (value) {
    emit('open')
  } else {
    emit('close')
  }
})
</script>
