import BulkActionsController from './bulk_actions_controller'

type ResponseData = { type: string; text: string }

interface DialogInstance {
  el: HTMLElement | null
  closeDialog: () => void
}

export default class extends BulkActionsController {
  importAvvoka(event: PointerEvent) {
    event.preventDefault()

    const target = event.currentTarget as HTMLElement
    const templatePackId = target.dataset.templatePackId

    avv_dialog({
      uploadTitle: `<strong>${localizeText(
        'template_pack_upload.title'
      )}</strong>`,
      uploadDescription: localizeText('template_pack_upload.desc'),
      uploadSuccessCallback: (
        instance: DialogInstance,
        _: unknown,
        data: ResponseData
      ) => {
        instance.closeDialog()

        avv_dialog({
          snackStyle: data.type as 'alert' | 'error' | 'success' | 'notice',
          snackMessage: data.text,
          onHide: () => window.location.reload()
        })
      },
      uploadErrorCallback: (
        instance: DialogInstance,
        _: unknown,
        data: ResponseData
      ) => {
        instance.closeDialog()

        avv_dialog({
          snackStyle: data.type as 'alert' | 'error' | 'success' | 'notice',
          snackMessage: data.text
        })
      },
      dropzoneOptions: {
        url: `/template_packs/imports/avvoka?template_pack_id=${templatePackId}`,
        paramName: 'file',
        acceptedFiles: '.zip,.avvoka',
        timeout: 90 * 1000,
        parallelUploads: 100
      }
    })
  }
}
