import {DOMEmit, getURLTab, onDomContentLoaded, setURLTab} from "../features/dom_utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";


export interface ButtonGroupController extends ExposeStimulus<typeof ButtonGroupController> {}
export class ButtonGroupController extends StimulusControllerBase {

  static values = { isTemplatePackEdit: Boolean }

  static activeClasses = ['ring-blue-500', 'z-10', 'border-primary-500', 'outline-none']

  connect() {
    onDomContentLoaded(() => {
      const tab = getURLTab()
      const button = document.querySelector(`[data-key="${tab}"]`) as HTMLButtonElement
      if(button) button.click()
    })
  }

  onButtonClick(event: MouseEvent) {
    if(this.isAlreadySelected(event)) return
    this.handleButtonClasses(event)
    this.handleWrapperClasses(event)
    this.handleURL(event)
    if(this.isTemplatePackEditValue) this.handleTemplatePackEdit(event)
    DOMEmit('button-group:change', {key: this.button(event).dataset.key!})
  }

  isAlreadySelected(event: MouseEvent): boolean {
    const button = this.button(event)
    return button.classList.contains('ring-blue-500')
  }

  handleURL(event: MouseEvent) {
    const button = this.button(event)
    const key = button.dataset.key!
    const input = document.querySelector('#tab') as HTMLInputElement
    setURLTab(key, input)
  }

  handleButtonClasses(event: MouseEvent) {
    const button = this.button(event)
    const activeButton = button.parentElement!.querySelector('.ring-blue-500')!
    button.classList.add(...ButtonGroupController.activeClasses)
    activeButton.classList.remove(...ButtonGroupController.activeClasses)
  }

  handleWrapperClasses(event: MouseEvent) {
    const button = this.button(event)
    const key = button.dataset.key!
    const activeWrapper = document.querySelector('.active-wrapper')!
    const showElement = document.getElementById(key)!
    showElement.classList.remove('hidden')
    showElement.classList.add('active-wrapper')
    activeWrapper.classList.add('hidden')
    activeWrapper.classList.remove('active-wrapper')
  }

  button(event: MouseEvent): HTMLElement {
    const target = event.target as HTMLElement
    return target.closest('button')! as HTMLElement
  }

  handleTemplatePackEdit(event: MouseEvent) {
    const button = this.button(event)
    const key = button.dataset.key!
    const saveButton = document.getElementById('update')!
    saveButton.setAttribute('data-current-section', key)
    DOMEmit('questionnaire:align-toc')
  }
}

export default ButtonGroupController
