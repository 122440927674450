import {type ExposeStimulus} from "./base_controller";
import CheckAllController from "./check_all_controller";

export interface BulkActionsController extends ExposeStimulus<typeof BulkActionsController> {}
export class BulkActionsController extends CheckAllController {
  static targets = [ "container", "form", "bulkActionInput", "recordInput" ] as const
  static classes = [ "hide" ] as const

  updateContainer () {
    if (!this.hasContainerTarget) return false

    const hideClass =  this.hasHideClass ? this.hideClass : 'hidden'

    if (this.checked.length > 0) {
      this.containerTarget.classList.remove(hideClass)
    } else {
      this.containerTarget.classList.add(hideClass)
    }
  }

  submitForm (action: string) {
    this.bulkActionInputTarget.value = action
    this.formTarget.submit()
  }

  archive (event: Event) {
    event.preventDefault()
    this.submitForm('archive')
  }

  assign(event: Event) {
    const triggerBtn = (event?.target as HTMLElement)?.closest<HTMLElement>(".modal-trigger")
    if (!triggerBtn) return false

    this.formTarget.action = triggerBtn.dataset.formAction

    if (this.recordInputTarget) this.recordInputTarget.value = triggerBtn.dataset.recordInputValue
    if (triggerBtn.dataset.method) this.formTarget.querySelector('input[name="_method"]').value = triggerBtn.dataset.method
  }

  delete (event: Event) {
    event.preventDefault()
    const methodInput = this.formTarget.querySelector<HTMLInputElement>('input[name="_method"]')
    if (!methodInput) return false

    methodInput.value = "delete"
    this.submitForm('delete')
  }

  patch (event: Event) {
    event.preventDefault()
    const methodInput = this.formTarget.querySelector<HTMLInputElement>('input[name="_method"]')
    if (!methodInput) return false

    methodInput.value = "patch"
    this.submitForm('patch')
  }

  activate (event: Event) {
    event.preventDefault()
    this.submitForm('activate')
  }

  deactivate (event: Event) {
    event.preventDefault()
    this.submitForm('deactivate')
  }

  export (event: Event) {
    event.preventDefault()
    this.submitForm('export')
  }

  destroy (event: Event) {
    event.preventDefault()
    this.submitForm('destroy')
  }
}

export default BulkActionsController