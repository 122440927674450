import { Controller } from 'stimulus'
import { getElementByIdStrict } from '../features/_abstract/utils/dom'

export default class TemplateSettingsController extends Controller {
  uploadAvvokaFormat (event: PointerEvent) {
    avv_dialog({
      confirmTitle: localizeText('template.dialog.save.title'),
      confirmMessage: localizeText('template.dialog.save.message'),
      okButtonText: localizeText('template.dialog.save.title'),
      inputLabel: localizeText('template.dialog.save.prompt'),
      confirmCallback: (value: boolean, inputValue?: string) => {
        if (value) {
          this.createAndSubmitTemplateForm((event.target as HTMLInputElement).files, inputValue ?? '')
        }
      }
    })
  }

  onSignMethodChange(e){
    const signingMethod = !!e.target.value
    this.handleSkipToSignCheckBoxDisplay(signingMethod)
  }

  handleSkipToSignCheckBoxDisplay(value: boolean) {
    const formGroup = document.getElementById('skip-to-signature-group') as HTMLElement
    formGroup.style.display = value ? 'block' : 'none'
    if(!value) {
      const checkbox = document.getElementById('template_skip_to_signature') as HTMLInputElement
      checkbox.checked = false
    }
  }

  createAndSubmitTemplateForm (files: FileList, publishMessage: string) {
    const form = getElementByIdStrict<HTMLFormElement>('template-form')
    form.action = `/templates/imports/avvoka`
    form.method = 'post'

    const methodInput = form.querySelector<HTMLInputElement>('input[name="_method"]')
    if (methodInput) {
      methodInput.value = 'post'
    }

    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.name = 'archive'
    fileInput.files = files
    form.appendChild(fileInput)

    const templateInput = document.createElement('input')
    templateInput.name = 'template_id'
    templateInput.value = String(AvvStore.state.template_id)
    form.appendChild(templateInput)

    getElementByIdStrict<HTMLInputElement>('template_published').value = 'false'
    getElementByIdStrict<HTMLInputElement>('template_publish_message').value = publishMessage

    getElementByIdStrict('submit-button').click()
  }
}
