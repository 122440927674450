<template>
  <GlobalDialog
    class="square bg-white !p-4 gap-4 !rounded-md flex min-w-[450px] flex-col dialog !max-h-[90vh]"
    wrapper-class="!bg-gray-500 !bg-opacity-75 transition-opacity"
  >
    <h1 v-text="localize('title')" />
    <p v-text="localize('message')" />
    <label
      for="password"
      class="text-sm font-bold"
      v-text="localize('label')"
    />
    <input
      id="password"
      v-model="password"
      type="password"
      required
      class="avv-input-tw"
    />
    <div class="grid grid-cols-2 gap-2 w-full">
      <button
        class="avv-button w-full secondary"
        @click="emit('callback', false)"
        v-text="localize('cancel')"
      />
      <button
        class="avv-button w-full"
        @click="save"
        v-text="localize('save')"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import GlobalDialog from '../dialogs/GlobalDialog.vue'

import { ref } from 'vue'
import { docuSignBatches } from '@api/all'

const props = defineProps<{
  batch_id: number
}>()

const password = ref<string>('')

const emit = defineEmits<{
  (e: string, success: boolean): void
}>()

const save = async () => {
  await docuSignBatches.publish({
    data: { id: props.batch_id, password: password.value }
  })

  emit('callback', true)
}

const localize = (key: string) =>
  window.localizeText(`docu_sign.dashboard.publish.${key}`)
</script>
