import { Controller } from 'stimulus'
import { createApp, type Component } from 'vue'
import QuestionnaireMerge from '../features/questionnaire_merge/QuestionnaireMerge.vue'

declare global {
  interface Window {
    questionnaireConflicts: Record<
      Backend.Questionnaire.Attribute,
      Backend.Questionnaire.Conflict
    >
    breakingConflicts: Record<
      Backend.Questionnaire.Attribute,
      Backend.Questionnaire.Conflict
    >
    resolvedValues: Record<
      Backend.Questionnaire.Attribute,
      Backend.Questionnaire.ResolvedValue
    >
  }
}

export default class QuestionnaireMergeController extends Controller {
  connect() {
    window.addEventListener('DOMContentLoaded', () => {
      const app = createApp(QuestionnaireMerge as Component, {
        conflicts: window.questionnaireConflicts,
        breakingConflicts: window.breakingConflicts,
        resolvedValues: window.resolvedValues
      })
      app.mount('#conflicts')
    })
  }
}
