import {ascendantNodeByTag} from "../global.utils";
import {onDomContentLoaded} from "../features/dom_utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface AvvSelectController extends ExposeStimulus<typeof AvvSelectController> {}
export class AvvSelectController extends StimulusControllerBase {

  static targets = ['select', 'searchInput', 'formInput', 'addButton'] as const

  connect() {
    onDomContentLoaded(this.init.bind(this))
  }

  init(){
    avv_select_register(this.selectTarget, (_, instance) => {
      instance.addListener("select", (option: HTMLElement) => this.setInputValue(option))
      // instance.addListener("open", () => this.handlePosition({isOpen: true}))
      // instance.addListener("close", () => this.handlePosition({isOpen: false}))
      const value = this.formInputTarget.getAttribute('value') as string
      instance.addListener('after_init', () => {
        this.selectOption(value, instance.$)
      })
      this.preventSubmitFormOnEnter()
    })
  }

  preventSubmitFormOnEnter(){
    this.selectTarget.addEventListener('keydown', e => {
      if(e.key == 'Enter') {
        this.addButtonTarget.click()
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    })
  }

  selectOption(value: string | string[], select: HTMLElement, silent = false) {
    if(!value) return
    if(!this.isMulti){
      const isOptionToSelect = (option: HTMLElement) => option.getAttribute("value")?.toLowerCase() === String(value).toLowerCase()
      const optionToSelect = avv_select_find_option(select, isOptionToSelect) as HTMLUnknownElement
      if(optionToSelect) avv_select_option(optionToSelect, silent, select)
    } else {
      value = JSON.parse(value) as string[]
      const options = avv_select_get_all_options(select)
      options.forEach(option => {
         const optionValue = option.getAttribute('value')
         if(value.map(el => String(el).toLowerCase()).includes(optionValue)) avv_select_option(option, silent, select)
      })
    }
  }

  addOption(){
    const value = this.searchInputTarget.value as string
    const option = this.createOption(value)
    const menu = this.selectTarget.querySelector('.menu')
    menu.appendChild(option)
    this.selectOption(value, this.selectTarget)
    this.notifyAddOption(value)
  }

  notifyAddOption(addedValue: string){
    window.dispatchEvent(new CustomEvent('avv-select-option-add', {detail: {addedValue, select: this.selectTarget}}))
  }

  createOption(value: string){
    const option = document.createElement('avv-option')
    option.textContent = value
    option.setAttribute('value', value)
    return option
  }

  search(){
    const value = this.searchInputTarget.value.toLowerCase() as string
    const options = avv_select_get_all_options(this.selectTarget)
    options.forEach((option: HTMLElement) => {
      const text = option.textContent?.toLowerCase()
      if(text?.includes(value)) option.classList.remove('hidden')
      else option.classList.add('hidden')
    })
  }

  setInputValue(option: HTMLElement){
    let value
    if(this.isMulti) {
      value = this.selectedOptions.map(option => option.getAttribute('value'))
      this.formInputTarget.setAttribute('value', String(value[0]))
      this.handleInputs(value, this.formInputTarget.getAttribute('name'))
    } else {
      value = option?.getAttribute('value')
      this.formInputTarget.setAttribute('value', String(value))
    }
  }

  handleInputs(value: string[], name: string){
    const currentInputs = Array.from(document.querySelectorAll(`input[name="${name}"]`))
    const currentValues = currentInputs.map(input => input.value)
    const valuesToAdd = value.filter(val => !currentValues.includes(val))
    const valuesToRemove = currentValues.filter(val => !value.includes(val))
    const inputsToRemove = valuesToRemove.map(val => currentInputs.find(input => input.value.toString() == val.toString()))
    valuesToAdd.forEach(val => this.createInput(val, name))
    inputsToRemove.forEach(input => input.remove())
    const dupliciteInputs = Array.from(document.querySelectorAll(`input[name="${name}"][value="${value[0]}"]`))
    dupliciteInputs.filter(input => !input.dataset.avvSelectTarget).forEach(input => input.remove())
  }

  createInput(value, name){
    const input = document.createElement("input")
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    input.style.display = "none"
    this.selectTarget.appendChild(input)
  }

  handlePosition({isOpen} : {isOpen: boolean}){
    const rect = this.selectTarget.getBoundingClientRect()
    this.selectTarget.style.position = isOpen ? 'absolute' : 'relative'
    this.selectTarget.style.top = isOpen ? rect.top + 'px' : ''
    this.selectTarget.style.maxWidth = isOpen ? rect.width + 'px' : ''
    const tableCell = ascendantNodeByTag('td', this.selectTarget)
    tableCell.style.minWidth = isOpen ? rect.width + 'px' : ''
  }

  isAttribute(attribute: string){
    return this.selectTarget.getAttribute(attribute) == 'true'
  }

  get isMulti(){
    return this.isAttribute('multi')
  }

  get isOpenSelect(){
    return this.isAttribute('open-select')
  }

  get selectedOptions(){
    return Array.from(this.selectTarget.querySelectorAll("avv-option[selected]"))
  }
}

export default AvvSelectController
