<template>
  <GlobalDialog class="flex w-1/5 flex-col dialog">
    <h2 class="text-black font-bold" v-text="localize('title')"/>
    <div class="flex justify-center flex-col h-[125px]">
      <div class="flex items-center">
        <ACheckbox id="questions" v-model="options.questions"/>
        <label class="cursor-pointer" for="questions" v-text="localize('modes.questions')"/>
      </div>
      <div class="flex items-center">
        <ACheckbox id="transpose" v-model="options.transpose"/>
        <label class="cursor-pointer" for="transpose" v-text="localize('modes.transpose')"/>
      </div>
    </div>
    <div class="flex justify-between">
      <button class="avv-button secondary" @click="close" v-text="localize('close')"/>
      <button class="avv-button" @click="download" v-text="localize('download')"/>
    </div>
  </GlobalDialog>
</template>
<script lang="ts">
  import { defineComponent, reactive } from "vue";

  import ACheckbox from './../_abstract/ACheckbox.vue'
  import GlobalDialog from '../dialogs/GlobalDialog.vue'

  export default defineComponent({
    name: 'ExportDialog',
    components: { GlobalDialog, ACheckbox },
    props: [ 'templates' ],
    emits: [ 'callback' ],
    setup(props, ctx) {
      const options = reactive({
        questions: false,
        transpose: false,
        data: false
      })

      const localize = (key: string) => window.localizeText(`documents.excel.export.${key}`)

      const close = () => {
        ctx.emit('callback')
      }

      const download = () => {
        close()

        window.avv_download(
          '/templates/exports/xlsx',
          {
            template_ids: props.templates,
            ...options
          }
        )
      }

      return {
        options,
        close,
        download,
        localize
      }
    }
  })
</script>
