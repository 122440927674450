import { Controller } from 'stimulus'
import { DOMListen } from "../features/dom_utils";

export default class ToggleSplitController extends Controller {
  connect(){
    const editor = document.querySelector('.avv-editor')
    this.handleArrows(editor)
    DOMListen('document-conditions-update', () => {
      this.handleArrows(editor)
    })
  }
  moveForward(e: MouseEvent){
    this.move(1, e)
  }

  moveBack(e: MouseEvent){
    this.move(-1, e)
  }

  move(direction: -1 | 1, e: MouseEvent){
    if(e.target instanceof HTMLElement && e.target.classList.contains('disabled')) return
    const currentEditor = document.querySelector("[id*='q-editor']:not(.hidden)")!
    const availableEditors = currentEditor.parentElement!.querySelectorAll("[id*='q-editor'][data-active='true'], [id*='q-editor']:not(.hidden)")
    const availableEditorIds = Array.from(availableEditors).map(e => e.id)
    const otherEditorId = availableEditorIds[availableEditorIds.indexOf(currentEditor.id) + direction]
    const otherEditor = document.getElementById(otherEditorId)
    if(!currentEditor || !otherEditor || !otherEditor.classList.contains('avv-editor')) {
      return
    }
    currentEditor.classList.toggle('hidden')
    otherEditor.classList.toggle('hidden')
    this.handleArrows(otherEditor)
  }

  handleArrows(editor){
    const forwardArrow = editor.querySelector('.arrow-forward')
    const backArrow = editor.querySelector('.arrow-back')
    if(!backArrow || !forwardArrow) return
    const availableEditors = editor.parentElement!.querySelectorAll("[id*='q-editor'][data-active='true']")
    const availableEditorIds = Array.from(availableEditors).map(e => e.id)
    const forwardAvailable = availableEditorIds.indexOf(editor.id) < availableEditorIds.length - 1
    const backAvailable = availableEditorIds.indexOf(editor.id) > 0
    forwardArrow.classList[forwardAvailable ? 'remove' : 'add']('opacity-50', 'disabled')
    backArrow.classList[backAvailable ? 'remove' : 'add']('opacity-50', 'disabled')
  }
}
